import * as React from "react";
import clsx from "clsx";
import { GetStaticProps } from "next";
import { useRouter } from "next/router";
import { Trans } from "@lingui/macro";
import Link from "next/link";

import { Button } from "ui";

import { getStaticTranslationProps } from "utils";
import VerbsAutosuggest from "components/verbs-autosuggest";
import VerbsAutosuggestMobile from "components/verbs-autosuggest-mobile/VerbsAutosuggestMobile";
import { AutosuggestProps } from "ui/src/components/autosuggest/Autosuggest";
import { Verb } from "types";
import _classes from "./index.module.scss";

interface Props {
  isMobile: boolean;
}

function IndexVerbsAutosuggest(props: AutosuggestProps) {
  return (
    <VerbsAutosuggest
      {...props}
      classes={{
        input: _classes.autosuggestInput,
      }}
    />
  );
}

function Home({ isMobile = false }: Props) {
  const router = useRouter();
  const onVerbSelected = React.useCallback(
    (verb) => {
      if (!verb) {
        return;
      }
      router.push(`verbs/${verb.id}`);
    },
    [router]
  );
  const Autosuggest = React.useMemo(
    () => (isMobile ? VerbsAutosuggestMobile : IndexVerbsAutosuggest),
    [isMobile]
  );
  return (
    <React.Fragment>
      <Autosuggest
        id="home-verbs-autosuggest"
        onSuggestionSelected={onVerbSelected}
        getSuggestionDisplayValue={(suggestion: Verb) => suggestion.infinitive}
      />
      <span className={clsx(_classes.divider, "caption")}>
        <Trans>ou</Trans>
      </span>
      <Link href="/verbs/exercise">
        <a>
          <Button className={_classes.cta} color="primary" contained>
            <Trans>Pratiquer avec des exercices</Trans>
          </Button>
        </a>
      </Link>
    </React.Fragment>
  );
}

export const getStaticProps: GetStaticProps = getStaticTranslationProps;

export default Home;
